/* 
==============================================
CONTENT:	Screen Theory Build 1.0.1
AUTHOR:		Jason Schrode
==============================================

TABLE OF CONTENTS:

0. Reset
1. Variables
2. Body Overrides
3. Header
4. Landing Page
5. Footer
6. Contact Page

==============================================
*/

/* 0. Reset ================================= */
@import 'reset';


/* 1. Variables ================================= */

$mainFont: niveau-grotesk, sans-serif;
$textColor: #17405B;


/* 2. Body Overrides ================================= */

* {
	box-sizing: border-box;
}

body {
	width: 100%;
	height: 100vh;
	max-width: 1920px;
	margin: 0 auto;
	background: black;
}










































